import ClientStorage from '@naturehouse/nh-essentials/lib/storage/Storage';
import IndexedDBStrategy from '@naturehouse/nh-essentials/lib/storage/strategies/IndexedDBStrategy';
import iStorageStrategy from '@naturehouse/nh-essentials/lib/storage/iStorageStrategy.interface';
import { FeatureToggle, isFeatureEnabled } from '../util/featureToggle';

export type TranslationType = Record<string, string>;

export default class TranslationManager {
    static #instance: TranslationManager | null = null;

    static #loading: boolean = false;

    static async getInstance(): Promise<TranslationManager> {
        if (this.#instance === null) {
            const storage = await ClientStorage.retrieveStorage<string>(
                this.#databaseName,
                IndexedDBStrategy
            );
            this.#instance = new TranslationManager(storage);
        }

        if (Object.keys(this.#instance.translations).length === 0 && this.#loading === false) {
            this.#loading = true;
            try {
                const translations = await this.#instance.#initialize();
                this.#instance.translations = translations;
            } catch (error) {
                throw error;
            } finally {
                this.#loading = false;
            }
        }

        if (this.#loading === false) {
            return this.#instance;
        }

        await new Promise((resolve) => {
            const interval = setInterval(() => {
                if (this.#loading === false) {
                    clearInterval(interval);
                    resolve(true);
                }
            }, 250);
        });

        return this.#instance;
    }

    static destroyInstance(): void {
        this.#instance = null;
    }

    static readonly #databaseName = 'translationsDB';

    readonly #language = (): string => document.documentElement.dataset.language ?? 'en';

    readonly #storage: iStorageStrategy<string>;

    #translations: TranslationType = {};

    get translations(): TranslationType {
        return this.#translations;
    }

    set translations(translations: TranslationType) {
        this.#translations = translations;
    }

    private constructor(storage: iStorageStrategy<string>) {
        this.#storage = storage;
    }

    async #initialize(): Promise<TranslationType> {
        const currentHash = await this.#getHashFromJson();
        const cachedHash = (await this.#storage.getItem('hash')) ?? null;

        if (currentHash !== null && cachedHash === currentHash) {
            return this.#storage.getItems();
        }

        const translations = await this.#getTranslationsFromJSON();
        await this.#storage.clear();
        await this.#storage.setItems(translations);

        if (currentHash !== null) {
            await this.#storage.setItem('hash', currentHash);
        }

        return translations;
    }

    public translate(key: string, placeholders: Record<string, string> = {}): string {
        if (isFeatureEnabled(FeatureToggle.TRANSLATIONS)) {
            return `${key}:messages`;
        }

        const translation = this.#translations[key];
        if (!translation) {
            return key;
        }

        if (Object.keys(placeholders).length === 0) {
            return translation;
        }

        return this.#replacePlaceholders(translation, placeholders);
    }

    readonly #replacePlaceholders = (
        translation: string,
        placeholders: Record<string, string>
    ): string => {
        let translatedString = translation;

        for (const [varKey, varValue] of Object.entries(placeholders)) {
            translatedString = translatedString.replace(new RegExp(`{${varKey}}`, 'g'), varValue);
        }

        return translatedString;
    };

    async #getTranslationsFromJSON(): Promise<TranslationType> {
        try {
            const json = await import(
                /* webpackChunkName: "async-messages-translations" */ `../../translations/${this.#language()}.json`
            );

            return json.translations;
        } catch (e: unknown) {
            const error = e as Error;
            error.message = `Importing translations from JSON: ${error.message}`;
            throw error;
        }
    }

    async #getHashFromJson(): Promise<string | null> {
        try {
            const hash = await import(
                /* webpackChunkName: "async-translations-hash" */ `../../translations/${this.#language()}-hash.json`
            );

            return hash.hash;
        } catch (error) {
            return null;
        }
    }
}
